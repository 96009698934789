<template>
  <div id="app">
    <!-- <TopHeader v-if="!$route.path.includes('/login')" /> -->

    <router-view />

    <loader :isLoading="isLoading" v-if="!$route.path.includes('/thanks')" />
  </div>
</template>

<script>
// import TopHeader from "@/components/TopHeader.vue";

import Loader from "@/components/Loader.vue";

import { mapState } from "vuex";
import { provide } from "vue";

export default {
  name: "App",
  components: {
    Loader,
    // TopHeader,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  setup() {
    provide(/* key */ "theme", /* value */ "light");
  },
  computed: {
    ...mapState(["apiSuccess", "errorMessage", "successMessage"]),
  },
  watch: {
    errorMessage() {
      if (this.errorMessage !== "") {
        this.$toast.error(this.errorMessage);
      }
    },
    successMessage() {
      if (this.apiSuccess == true && this.successMessage !== "") {
        this.$toast.success(this.successMessage);
      }
    },
  },
};
</script>

<style>
*::-webkit-scrollbar {
  display: none;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
</style>
