<template>
  <div>
    <!-- <div class="carousel">
      <div class="cards" ref="cards">
        <div class="card" v-for="(card, index) in cards" :key="index">
          <div class="content">
            <h2>{{ card.title }}</h2>
            <p>{{ card.description }}</p>
          </div>
        </div>
      </div>
    </div> -->

    <Grid :cols="1">
      <div
        class="grid-item"
        v-for="(section, i) in sections"
        :key="i"
        :style="{
          paddingBottom: '35px',
        }"
      >
        <Column class="v-flex-start" gap="4px">
          <Typography
            as="span"
            color="#4b4b4b"
            fontWeight="600"
            :style="{
              paddingLeft: '12px',
            }"
          >
            {{ section.title }}
          </Typography>
          <Grid :cols="2" gap="6px" :horizontal="section.type == 'HORIZONTAL'">
            <div
              class="grid-item"
              v-for="(product, j) in section.products"
              :key="j"
            >
              <RoviCard
                kind="flat"
                backgroundColor="transparent"
                :customStyle="{ padding: '0px' }"
                @click="goToProductDetailPage(product.skuId)"
              >
                <Column gap="14px">
                  <RoviImage :src="product.images[0].src" cover />
                  <Column gap="8px" class="v-baseline">
                    <Typography
                      as="span"
                      color="#808080"
                      fontSize="0.75em"
                      fontWeight="500"
                      fontType="caps"
                    >
                      {{ product.brand }}
                    </Typography>
                    <Typography
                      as="span"
                      color="#0d0d0d"
                      fontSize="0.80em"
                      fontWeight="600"
                    >
                      {{ product.name }}
                    </Typography>
                    <Row gap="8px" class="h-baseline">
                      <Typography
                        as="span"
                        color="#0d0d0d"
                        fontSize="0.75em"
                        fontWeight="800"
                      >
                        {{ product.price.current_price }}
                      </Typography>
                      <Typography
                        as="span"
                        decoration="line-through"
                        color="#808080"
                        fontSize="0.75em"
                        fontWeight="600"
                      >
                        {{ product.price.old_price }}
                      </Typography>
                      <Typography
                        as="span"
                        color="#289823"
                        fontSize="0.75em"
                        fontWeight="600"
                      >
                        {{ product.price.discount }}
                      </Typography>
                    </Row>
                    <RoviTag
                      :colorConfig="{
                        backgroundColor: '#e88c24',
                        color: '#fff',
                      }"
                      :textStyle="{
                        fontSize: '0.6rem',
                        fontWeight: '600',
                        color: '#fff',
                      }"
                      :style="{
                        padding: '5px',
                      }"
                      :icon="require('@/assets/svgs/timer.svg')"
                    >
                      {{ product.tag.toUpperCase() }}
                    </RoviTag>
                  </Column>
                </Column>
              </RoviCard>
            </div>
          </Grid>
          <HorizontalDivider height="3px" color="#dbdbdb" />
        </Column>
      </div>
    </Grid>
  </div>
</template>

<script>
// import { Carousel, Slide } from "vue3-carousel";

import "vue3-carousel/dist/carousel.css";

export default {
  name: "Home",
  components: {
    // Carousel,
    // Slide,
  },
  data() {
    return {
      sections: [
        {
          title: "Recent Launches",
          type: "HORIZONTAL",
          products: [
            {
              skuId: 1,
              images: [{ src: require("../assets/images/plp/sunglasses.jpg") }],
              brand: "Nike",
              name: "Air Max 270",
              description:
                "Introducing our Classic Product, the perfect accessory to keep your essentials organized and secure. Made from high-quality, genuine leather, this wallet is both durable and stylish, making it a timeless addition to your wardrobe.",
              price: {
                current_price: "₹2100",
                old_price: "₹5000",
                discount: "58% off",
              },
              tag: "FIRST BUY OFFER",
            },
            {
              skuId: 2,
              images: [{ src: require("../assets/images/plp/sunglasses.jpg") }],
              brand: "Adidas",
              name: "Ultraboost 21",
              description:
                "Introducing our Classic Product, the perfect accessory to keep your essentials organized and secure. Made from high-quality, genuine leather, this wallet is both durable and stylish, making it a timeless addition to your wardrobe.",
              price: {
                current_price: "₹3200",
                old_price: "₹8000",
                discount: "60% off",
              },
              tag: "FIRST BUY OFFER",
            },
            {
              skuId: 3,
              images: [{ src: require("../assets/images/plp/sunglasses.jpg") }],
              brand: "New Balance",
              name: "Fresh Foam 1080v11",
              description:
                "Introducing our Classic Product, the perfect accessory to keep your essentials organized and secure. Made from high-quality, genuine leather, this wallet is both durable and stylish, making it a timeless addition to your wardrobe.",
              price: {
                current_price: "₹2800",
                old_price: "₹7000",
                discount: "60% off",
              },
              tag: "FIRST BUY OFFER",
            },
            {
              skuId: 4,
              images: [{ src: require("../assets/images/plp/sunglasses.jpg") }],
              brand: "Asics",
              name: "GEL-Kayano 28",
              description:
                "Introducing our Classic Product, the perfect accessory to keep your essentials organized and secure. Made from high-quality, genuine leather, this wallet is both durable and stylish, making it a timeless addition to your wardrobe.",
              price: {
                current_price: "₹3800",
                old_price: "₹9500",
                discount: "60% off",
              },
              tag: "FIRST BUY OFFER",
            },
          ],
        },
        {
          title: "Bestsellers",
          type: "HORIZONTAL",
          products: [
            {
              skuId: 9,
              images: [{ src: require("../assets/images/plp/sunglasses.jpg") }],
              brand: "Reebok",
              name: "Nano X1",
              description:
                "Introducing our Classic Product, the perfect accessory to keep your essentials organized and secure. Made from high-quality, genuine leather, this wallet is both durable and stylish, making it a timeless addition to your wardrobe.",
              price: {
                current_price: "₹2100",
                old_price: "₹5000",
                discount: "58% off",
              },
              tag: "Bestseller",
            },
            {
              skuId: 10,
              images: [{ src: require("../assets/images/plp/sunglasses.jpg") }],
              brand: "Hoka One One",
              name: "Bondi 7",
              description:
                "Introducing our Classic Product, the perfect accessory to keep your essentials organized and secure. Made from high-quality, genuine leather, this wallet is both durable and stylish, making it a timeless addition to your wardrobe.",
              price: {
                current_price: "₹2999",
                old_price: "₹7999",
                discount: "63% off",
              },
              tag: "Bestseller",
            },
            {
              skuId: 11,
              images: [{ src: require("../assets/images/plp/sunglasses.jpg") }],
              brand: "Mizuno",
              name: "Wave Rider 25",
              description:
                "Introducing our Classic Product, the perfect accessory to keep your essentials organized and secure. Made from high-quality, genuine leather, this wallet is both durable and stylish, making it a timeless addition to your wardrobe.",
              price: {
                current_price: "₹3499",
                old_price: "₹9999",
                discount: "65% off",
              },
              tag: "Bestseller",
            },
            {
              skuId: 12,
              images: [{ src: require("../assets/images/plp/sunglasses.jpg") }],
              brand: "On",
              name: "Cloud X",
              description:
                "Introducing our Classic Product, the perfect accessory to keep your essentials organized and secure. Made from high-quality, genuine leather, this wallet is both durable and stylish, making it a timeless addition to your wardrobe.",
              price: {
                current_price: "₹2499",
                old_price: "₹5999",
                discount: "58% off",
              },
              tag: "Bestseller",
            },
          ],
        },
        {
          title: "2xn Grid",
          type: "VERTICAL",
          products: [
            {
              skuId: 5,
              images: [{ src: require("../assets/images/plp/sunglasses.jpg") }],
              brand: "Under Armour",
              name: "UA HOVR Sonic 4",
              description:
                "Introducing our Classic Product, the perfect accessory to keep your essentials organized and secure. Made from high-quality, genuine leather, this wallet is both durable and stylish, making it a timeless addition to your wardrobe.",
              price: {
                current_price: "₹2500",
                old_price: "₹6000",
                discount: "58% off",
              },
              tag: "Exclusive",
            },
            {
              skuId: 6,
              images: [{ src: require("../assets/images/plp/sunglasses.jpg") }],
              brand: "Puma",
              name: "RS-X Cubed",
              description:
                "Introducing our Classic Product, the perfect accessory to keep your essentials organized and secure. Made from high-quality, genuine leather, this wallet is both durable and stylish, making it a timeless addition to your wardrobe.",
              price: {
                current_price: "₹2900",
                old_price: "₹7250",
                discount: "60% off",
              },
              tag: "Exclusive",
            },
            {
              skuId: 7,
              images: [{ src: require("../assets/images/plp/sunglasses.jpg") }],
              brand: "Saucony",
              name: "Endorphin Shift",
              description:
                "Introducing our Classic Product, the perfect accessory to keep your essentials organized and secure. Made from high-quality, genuine leather, this wallet is both durable and stylish, making it a timeless addition to your wardrobe.",
              price: {
                current_price: "₹4200",
                old_price: "₹10500",
                discount: "60% off",
              },
              tag: "Exclusive",
            },
            {
              skuId: 8,
              images: [{ src: require("../assets/images/plp/sunglasses.jpg") }],
              brand: "Brooks",
              name: "Glycerin 19",
              description:
                "Introducing our Classic Product, the perfect accessory to keep your essentials organized and secure. Made from high-quality, genuine leather, this wallet is both durable and stylish, making it a timeless addition to your wardrobe.",
              price: {
                current_price: "₹3500",
                old_price: "₹8750",
                discount: "60% off",
              },
              tag: "Exclusive",
            },
          ],
        },
      ],
    };
  },
  methods: {
    goToProductDetailPage(skuId) {
      this.$router.push({
        name: "PDP",
        params: {
          skuId: skuId,
        },
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.carousel__slide {
  padding: 5px;
}

.carousel__viewport {
  perspective: 2000px;
}

.carousel__track {
  transform-style: preserve-3d;
}

.carousel__slide--sliding {
  transition: 0.5s;
}

.carousel__slide {
  opacity: 0.9;
  transform: rotateY(-20deg) scale(0.9);
}

.carousel__slide--active ~ .carousel__slide {
  transform: rotateY(20deg) scale(0.9);
}

.carousel__slide--prev {
  opacity: 1;
  transform: rotateY(-10deg) scale(0.95);
}

.carousel__slide--next {
  opacity: 1;
  transform: rotateY(10deg) scale(0.95);
}

.carousel__slide--active {
  opacity: 1;
  transform: rotateY(0) scale(1.1);
}
</style>
