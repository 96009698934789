<template>
  <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
    <path
      d="M11.825 19.9q.1 0 .213-.063.112-.062.187-.137l7.875-7.875q.425-.425.613-.788.187-.362.187-.812 0-.5-.162-.888-.163-.387-.538-.762L16.3 4.7q-.275-.3-.7-.438-.425-.137-.825-.137-.375 0-.837.237-.463.238-.763.538l-.15.15 1.775 1.8q.375.375.55.8.175.425.175.925 0 1-.687 1.7-.688.7-1.688.7-.5 0-.937-.175-.438-.175-.788-.525l-1.75-1.65-4.25 4.25q-.1.1-.15.2-.05.1-.05.2 0 .2.15.362.15.163.35.163.1 0 .225-.075t.175-.125l3.325-3.325 1.325 1.325-3.325 3.325q-.1.1-.15.2-.05.1-.05.2 0 .2.15.35.15.15.35.15.125 0 .237-.075.113-.075.163-.125l3.325-3.325 1.325 1.325-3.3 3.325q-.1.1-.15.2-.05.1-.05.2 0 .225.138.362.137.138.362.138.1 0 .2-.05t.2-.15l3.325-3.3 1.325 1.325L11.525 19q-.1.1-.15.2-.05.1-.05.2 0 .2.15.35.15.15.35.15Zm0 1.875q-.875 0-1.575-.587-.7-.588-.75-1.463-.85-.125-1.4-.663-.55-.537-.675-1.387-.85-.125-1.375-.675t-.65-1.375q-.875-.1-1.475-.788-.6-.687-.6-1.587 0-.5.163-.925.162-.425.512-.775L9.675 5.9l3.05 3.05q.075.075.188.125.112.05.212.05.225 0 .375-.15t.15-.375q0-.1-.05-.225t-.125-.175l-3.5-3.5q-.25-.275-.637-.425-.388-.15-.788-.15-.375 0-.787.125-.413.125-.713.425l-3.3 3.3q-.275.275-.438.625-.162.35-.187.775-.025.45.063.8.087.35.262.625L2.1 12.15q-.425-.575-.65-1.288-.225-.712-.2-1.487.025-.7.288-1.4.262-.7.812-1.25L5.775 3.3q.525-.5 1.238-.775.712-.275 1.537-.275.775 0 1.512.325.738.325 1.313.9l.275.275.275-.275q.575-.575 1.325-.9.75-.325 1.525-.325.925 0 1.663.337.737.338 1.237.838l3.875 3.85q.6.625.913 1.362.312.738.312 1.613 0 .825-.325 1.525t-.975 1.35l-7.95 7.925q-.35.35-.787.537-.438.188-.913.188Zm-2.9-13.6Z"
    />
  </svg>
</template>

<script>
export default {
  name: "HandShakeSvg",
};
</script>
