import { createStore } from "vuex";
import { actions } from "./actions";
import { mutations } from "./mutations";

const store = createStore({
  state: {
    apiSuccess: true,
    errorMessage: "",
    successMessage: "",
    userDetails: [],
    productDetail: {},
    addresses: []
  },
  mutations,
  actions,
});

export default store;
