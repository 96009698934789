import axios from "axios";
import { BASE_URL } from "../utils/constants";
import { useCookies } from "vue3-cookies";
import { config, setConfig } from "../utils/Helper";

let { cookies } = useCookies();

setConfig({
  headers: {
    "X-Auth-Id": cookies.get("X-Auth-Id"),
    Authorization: "Bearer " + cookies.get("JWT-TOKEN"),
  },
});

export const actions = {
  async fetchUserDetails({ commit }) {
    try {
      const { data } = await axios.get(
        `${BASE_URL}/crypto/user/details/`,
        config
      );
      commit("GET_USER_DETAILS", data);
    } catch (e) {
      console.log(e);
    }
  },
  async fetchProductDetail({ commit }, payload) {
    try {
      // const { data } = await axios.post(`${BASE_URL}/product-detail/`, payload);
      // commit("SET_PRODUCT_DETAIL", data);
      commit("SET_PRODUCT_DETAIL", {
        skuId: payload.skuId,
        images: [{ src: require("../assets/images/plp/sunglasses.jpg") }],
        brand: "Adidas",
        name: "Ultraboost 21",
        description:
          "Introducing our Classic Product, the perfect accessory to keep your essentials organized and secure. Made from high-quality, genuine leather, this wallet is both durable and stylish, making it a timeless addition to your wardrobe.",
        price: {
          current_price: "Rs 3200",
          old_price: "Rs 8000",
          discount: "60% off",
        },
        details: {
          type: "face wash",
          ingedients:
            "aqua, sodium, cocomide, glycrein, ethylene, niacinamide, arabica seed extract",
          flavour: "coffee",
          weight: "100",
          color: "brown",
          formulation: "gel",
          shelf_life: "24",
          meant_for: "unisex",
          package_contents: "1 x 100gm coffee facewash",
          country_of_origin: "India",
        },
        notes_from_experts:
          "the caffeine in your face wash tones the skin and reduces puffiness, while vitamin B 3 fades age spots and uplifts the skin by treating hyperpigmentation",
        tag: "FIRST BUY OFFER",
      });
    } catch (e) {
      console.log(e);
    }
  },

  async fetchUserAddresses({ commit }) {
    try {
      // const { data } = await axios.get(`${BASE_URL}/user-addresses/`, payload);
      // commit("SET_USER_ADDRESSES", data);
      commit("SET_USER_ADDRESSES", [
        {
          name: 'Sumit Chauhan',
          mobile_no: '+91 9027293112',
          address_line_1: 'Pipalia, Bazpur, Distt: U.S.Nagar, Uttarakhand',
          address_line_2: '',
          landmark: 'Pipalia Road',
          pincode: '262401',
          state: 'Uttarakhand',
          city: 'Bazpur',
          nickname: 'Home'
        }
      ]);
    } catch (e) {
      console.log(e);
    }
  },
};
