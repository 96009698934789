import axios from "axios";
import { FILE_UPLOAD_URL } from "./constants";
// import { firebaseApp } from "../main";

export const uploadImageFile = async (_blob) => {
  const formData = new FormData();
  formData.append("image", _blob, _blob.name ? _blob.name : "sign");
  formData.append("isResizeRequired", true);
  const data = await axios
    .post(FILE_UPLOAD_URL, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .catch(() => {
      console.log("error");
    });
  return data.data.mediaVariantDTOs[0].url;
};

export const timeSince = (date) => {
  var seconds = Math.floor((new Date() - date) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " years";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minutes";
  }
  return Math.floor(seconds) + " seconds";
};

// export const getSymbolData = async (symbol) => {
//     let res;
//     var cryptoList = await firebaseApp.database().ref(`Crypto/${symbol}`);
//     cryptoList.on("value", (snapshot) => {
//         res = snapshot.val();
//     });

//     return res ? res : null
// }

export let config = null;
export function setConfig(value) {
  config = value;
}
