export const mutations = {
  API_STATUS(state, payload) {
    state.apiSuccess = payload;
  },
  ERR_MSG(state, payload) {
    state.errorMessage = payload;
  },
  GET_USER_DETAILS(state, payload) {
    state.userDetails = payload;
  },
  SET_PRODUCT_DETAIL(state, payload) {
    state.productDetail = payload;
  },
  SET_USER_ADDRESSES(state, payload) {
    state.addresses = payload;

  }
};
