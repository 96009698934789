<template>
  <div>
    <Column
      gap="18px"
      :style="{
        marginBottom: '95px',
      }"
    >
      <Carousel :autoplay="2000" :wrap-around="true">
        <Slide v-for="(image, i) in productDetail.images" :key="i">
          <RoviImage :src="image.src" cover />
        </Slide>

        <template #addons>
          <Pagination />
        </template>
      </Carousel>

      <Column gap="12px" class="v-baseline">
        <Typography
          as="span"
          color="#808080"
          fontSize="0.85em"
          fontWeight="500"
        >
          {{ productDetail.brand }}
        </Typography>
        <Typography as="span" color="#0d0d0d" fontSize="1.5em" fontWeight="600">
          {{ productDetail.name }}
        </Typography>
        <Row gap="8px" class="h-baseline">
          <Typography
            as="span"
            color="#0d0d0d"
            fontSize="0.9em"
            fontWeight="800"
          >
            {{ productDetail.price?.current_price }}
          </Typography>
          <Typography
            as="span"
            decoration="line-through"
            color="#808080"
            fontSize="0.75em"
            fontWeight="600"
          >
            {{ productDetail.price?.old_price }}
          </Typography>
          <Typography
            as="span"
            color="#289823"
            fontSize="0.85em"
            fontWeight="600"
          >
            {{ productDetail.price?.discount }}
          </Typography>
        </Row>
        <RoviTag
          :colorConfig="{
            backgroundColor: '#e88c24',
            color: '#fff',
          }"
          :textStyle="{
            fontSize: '0.75rem',
            fontWeight: '600',
            color: '#fff',
            productDetail,
          }"
          :style="{
            padding: '5px',
          }"
          :icon="require('@/assets/svgs/timer.svg')"
        >
          {{ productDetail.tag?.toUpperCase() }}
        </RoviTag>
      </Column>

      <HorizontalDivider height="4px" color="#e9e9e9" />

      <Column
        class="v-start"
        gap="8px"
        :style="{
          paddingLeft: '6px',
        }"
      >
        <Typography as="span" color="#000" fontWeight="600">
          revitalize your skin with a coffee face wash
        </Typography>
        <Typography
          as="span"
          color="#494747"
          :style="{
            textAlign: 'left',
          }"
        >
          {{ productDetail.description }}
        </Typography>
      </Column>

      <Column>
        <RoviAccordion v-if="productDetail?.details">
          <template v-slot:title>
            <Typography
              as="span"
              color="#000"
              fontWeight="600"
              fontSize="0.90em"
            >
              Product details
            </Typography>
          </template>
          <template v-slot:content>
            <Column
              gap="5px"
              :style="{
                marginTop: '10px',
              }"
            >
              <Row
                v-for="(key, i) in Object.keys(productDetail?.details)"
                :key="i"
              >
                <Typography
                  as="span"
                  color="#000"
                  fontWeight="400"
                  :style="{
                    textAlign: 'left',
                  }"
                >
                  <span :style="{ fontWeight: '500' }">{{ key }} : </span>
                  {{ productDetail?.details[key] }}
                </Typography>
              </Row>
              <HorizontalDivider height="4px" color="#e9e9e9" />
            </Column>
          </template>
        </RoviAccordion>

        <RoviAccordion v-if="productDetail?.notes_from_experts">
          <template v-slot:title>
            <Typography
              as="span"
              color="#000"
              fontWeight="600"
              fontSize="0.90em"
            >
              Notes from our experts
            </Typography>
          </template>
          <template v-slot:content>
            <Row
              :style="{
                marginTop: '8px',
              }"
            >
              <Typography
                as="span"
                color="#000"
                fontWeight="400"
                :style="{
                  textAlign: 'left',
                }"
              >
                {{ productDetail?.notes_from_experts }}
              </Typography>
            </Row>
          </template>
        </RoviAccordion>
      </Column>

      <HorizontalDivider height="4px" color="#e9e9e9" />

      <Row
        gap="1em"
        class="h-center"
        :style="{
          padding: '10px 8px 10px',
        }"
      >
        <HandShakeSvg />
        <Column>
          <Typography as="span" fontSize="0.9em" fontWeight="600" color="#000">
            this product is covered by ROVI promise
          </Typography>
          <Typography
            decoration="underline"
            as="span"
            fontWeight="600"
            fontSize="0.7em"
            color="gray"
            @click="this.isPromiseBottomSheetActive = true"
          >
            know more
          </Typography>
        </Column>
      </Row>

      <RoviBottomSheet
        :isActive="isPromiseBottomSheetActive"
        @closeBottomSheet="
          () => {
            this.isPromiseBottomSheetActive = false;
          }
        "
      >
        <RoviCard
          kind="elevated"
          fullWidth
          backgroundColor="#fff"
          :edgeColors="{
            top: '#999',
          }"
          :style="{
            textAlign: 'left',
          }"
        >
          <Column gap="24px">
            <Column gap="10px">
              <Column
                gap="8px"
                class="v-start"
                :style="{
                  padding: '8px 8px 0px 0px',
                }"
              >
                <RoviImage
                  :src="require('@/assets/images/rovi-icon.png')"
                  width="80px"
                  :aspectRatio="1 / 1"
                />

                <Typography
                  as="span"
                  color="gray"
                  fontSize="1em"
                  :style="{
                    letterSpacing: '2.4px',
                  }"
                >
                  ROVI PROMISE
                </Typography>
              </Column>
              <Typography
                as="span"
                color="#000"
                fontSize="1em"
                fontWeight="600"
              >
                we have got you covered
              </Typography>
            </Column>
            <HorizontalDivider color="#e3e3e3" height="1px" />
            <Column gap="16px">
              <Typography
                as="span"
                color="#000"
                fontWeight="400"
                fontSize="0.8em"
                >shop at member-exclusive prices</Typography
              >
              <Typography
                as="span"
                color="#000"
                fontWeight="400"
                fontSize="0.8em"
                >only handpicked quality products</Typography
              >
              <Typography
                as="span"
                color="#000"
                fontWeight="400"
                fontSize="0.8em"
                >24x7 on-demand assistance</Typography
              >
            </Column>
          </Column>
        </RoviCard>
      </RoviBottomSheet>
    </Column>

    <!-- Action Buttons -->
    <Column
      :style="{
        position: 'fixed',
        width: '100%',
        bottom: 0,
        left: 0,
      }"
    >
      <!-- Delivery -->
      <Row
        class="v-space-between"
        :style="{
          padding: '12px 8px 12px',
          backgroundColor: 'rgb(213 198 224)',
        }"
        @click="this.isAddressBottomSheetActive = true"
      >
        <Row class="h-center" gap="1em">
          <HomeSvg />
          <Typography as="span" fontSize="0.8em" fontWeight="400" color="#000">
            delivers in 7-10 days
            <span
              :style="{
                fontWeight: '700',
                fontSize: '0.8em',
              }"
              >262401</span
            >
          </Typography>
        </Row>
        <ChevronRightSvg />
      </Row>
      <Row>
        <RoviButton
          kind="flat"
          fullWidth
          :spacingConfig="{
            padding: '16px',
            buttonFaceStyles: {
              borderRightWidth: '0px',
            },
          }"
        >
          Go to Cart
        </RoviButton>
        <RoviButton
          kind="flat"
          :spacingConfig="{
            padding: '16px 15px',
          }"
          fullWidth
          showArrow
        >
          Buy Now
        </RoviButton>
      </Row>

      <RoviBottomSheet
        :isActive="isAddressBottomSheetActive"
        @closeBottomSheet="
          () => {
            this.isAddressBottomSheetActive = false;
          }
        "
      >
        <RoviCard
          kind="elevated"
          fullWidth
          backgroundColor="#fff"
          :edgeColors="{
            top: '#999',
          }"
          :style="{
            textAlign: 'left',
          }"
        >
          <Column
            gap="24px"
            :style="{
              paddingTop: '24px',
            }"
          >
            <Row class="v-space-between">
              <Typography
                as="span"
                color="#000"
                fontSize="1em"
                fontWeight="600"
                :style="{
                  width: '75%',
                }"
              >
                where should we deliver your order ?
              </Typography>
              <Typography
                as="span"
                color="#000"
                fontSize="0.8em"
                @click="addNewAddress"
              >
                + Add new
              </Typography>
            </Row>
            <HorizontalDivider color="#e3e3e3" height="1px" />
            <Column gap="16px">
              <Typography
                as="span"
                color="#000"
                fontWeight="400"
                fontSize="0.8em"
                v-if="addresses.length == 0"
              >
                No addresses yet
              </Typography>
              <RoviRadio
                :options="radioOptions"
                :defaultValue="defaultValue"
                :colorConfig="{
                  containerConfig: {
                    color: '#fff',
                    backgroundColor: '#c5c5c545',
                    borderColor: 'transparent',
                    activeBackgroundColor: '#c5c5c545',
                    activeBorderColor: '#000',
                  },
                }"
                v-model="selectedOption"
              >
                <template
                  v-slot:[option.templateName]
                  v-for="(option, i) in radioOptions"
                  :key="i"
                >
                  <RoviCard>
                    <Column>
                      <Typography as="span" color="#000" fontWeight="600">
                        {{ option.label }}
                      </Typography>
                      <Typography
                        as="span"
                        color="#896e6e"
                        fontSize="0.65rem"
                        fontWeight="500"
                      >
                        Tower N-12, Flat 301, <br />
                        Sector-151, Noida, <br />
                        Uttar Pradesh 201305
                      </Typography>
                    </Column>
                  </RoviCard>
                </template>
              </RoviRadio>
            </Column>
          </Column>
        </RoviCard>
      </RoviBottomSheet>

      <RoviBottomSheet
        :isActive="isNewAddressAddBottomSheetActive"
        @closeBottomSheet="
          () => {
            this.isNewAddressAddBottomSheetActive = false;
          }
        "
        maxHeight="100%"
      >
        <RoviCard
          kind="elevated"
          fullWidth
          backgroundColor="#fff"
          :edgeColors="{
            top: '#999',
          }"
          :style="{
            textAlign: 'left',
            height: '100vh',
          }"
        >
          <Column
            gap="24px"
            :style="{
              paddingTop: '24px',
            }"
          >
            <Row class="v-space-between">
              <Typography
                as="span"
                color="#000"
                fontSize="1em"
                fontWeight="600"
                :style="{
                  width: '75%',
                }"
              >
                where should we deliver your order ?
              </Typography>
            </Row>
            <HorizontalDivider color="#e3e3e3" height="1px" />
            <Column gap="16px">
              <Typography
                as="span"
                color="#000"
                fontWeight="400"
                fontSize="0.8em"
                v-if="addresses.length == 0"
              >
                No addresses yet
              </Typography>
              <RoviRadio
                :options="radioOptions"
                :defaultValue="defaultValue"
                :colorConfig="{
                  containerConfig: {
                    color: '#fff',
                    backgroundColor: '#c5c5c545',
                    borderColor: 'transparent',
                    activeBackgroundColor: '#c5c5c545',
                    activeBorderColor: '#000',
                  },
                }"
                v-model="selectedOption"
              >
                <template
                  v-slot:[option.templateName]
                  v-for="(option, i) in radioOptions"
                  :key="i"
                >
                  <RoviCard>
                    <Column>
                      <Typography as="span" color="#000" fontWeight="600">
                        {{ option.label }}
                      </Typography>
                      <Typography
                        as="span"
                        color="#896e6e"
                        fontSize="0.65rem"
                        fontWeight="500"
                      >
                        Tower N-12, Flat 301, <br />
                        Sector-151, Noida, <br />
                        Uttar Pradesh 201305
                      </Typography>
                    </Column>
                  </RoviCard>
                </template>
              </RoviRadio>
            </Column>
          </Column>
        </RoviCard>
      </RoviBottomSheet>
    </Column>
  </div>
</template>

<script>
import { Carousel, Pagination, Slide } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";

import HandShakeSvg from "../components/svgIcons/HandShakeSvg.vue";

import HomeSvg from "../components/svgIcons/HomeSvg.vue";
import ChevronRightSvg from "../components/svgIcons/ChevronRightSvg.vue";

import { mapState } from "vuex";

export default {
  name: "ProductDetail",
  components: {
    Carousel,
    Slide,
    Pagination,
    HandShakeSvg,
    HomeSvg,
    ChevronRightSvg,
  },
  data() {
    return {
      isPromiseBottomSheetActive: false,
      isAddressBottomSheetActive: false,
      isNewAddressAddBottomSheetActive: false,

      radioOptions: [
        {
          id: 1,
          label: "Home",
          value: "Home",
          templateName: "Home",
        },
        {
          id: 2,
          label: "Work",
          value: "Work",
          templateName: "Work",
        },
      ],
      defaultValue: {
        id: 1,
        label: "Home",
        value: "Home",
        templateName: "Home",
      },
      selectedOption: null,
    };
  },
  computed: {
    ...mapState(["productDetail", "addresses"]),
  },
  methods: {
    addToCart() {
      // Add logic to add product to cart
    },
    addNewAddress() {
      this.isAddressBottomSheetActive = false;

      this.isNewAddressAddBottomSheetActive = true;
      // this.radioOptions = [...this.radioOptions, newAddress];
    },
  },
  async mounted() {
    await this.$store.dispatch("fetchProductDetail", {
      skuId: this.$route.params.skuId,
    });
    await this.$store.dispatch("fetchUserAddresses", {
      skuId: this.$route.params.skuId,
    });
  },
};
</script>

<style scoped>
.pdp {
  /* Define styles for the entire PDP page */
  background-color: #f2f2f2;
  font-family: Arial, sans-serif;
  padding: 1rem;
}

.product-images {
  /* Define styles for the product images carousel */
}
.carousel {
  flex-direction: column;
}
.carousel__slide > img {
  width: 95vw;
}

ol {
  padding: 0;
}

.product-info {
  /* Define styles for the product information column */
  background-color: #ffffff;
  padding: 1rem;
  margin-top: 1rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.product-brand {
  /* Define styles for the product brand */
  font-weight: bold;
  color: #969696;
  font-size: 0.8rem;
}

.product-name {
  /* Define styles for the product name */
  font-weight: bold;
  font-size: 1rem;
  margin-top: 0.5rem;
}

.product-price {
  /* Define styles for the product price row */
  display: flex;
  flex-direction: row;
  margin-top: 0.5rem;
}

.current-price {
  /* Define styles for the current price */
  font-size: 13px;
  font-weight: bold;
  color: #222222;
}

.slashed-price {
  /* Define styles for the slashed price */
  font-size: 11px;
  color: #999999;
  margin-left: 0.5rem;
  text-decoration: line-through;
}

.discount {
  /* Define styles for the discount */
  font-size: 11px;
  color: #222222;
  margin-left: 0.5rem;
}

.promo-text {
  /* Define styles for the promo text */
  font-size: 12px;
  font-weight: 900;
  color: #4caf50;
  margin-top: 0.5rem;
}

.horizontal-divider {
  /* Define styles for the horizontal divider */
  border-top: 1px solid #999999;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.product-description {
  /* Define styles for the product description */
  font-size: 0.9rem;
  line-height: 1.5;
  margin-bottom: 2rem;
  text-align: left;
}

.fixed-buttons {
  /* Define styles for the fixed "Add to Cart" and "Buy Now" buttons row */
  background-color: #ffffff;
  position: fixed;
  bottom: 0;
  right: -20px;
  width: 100%;
  padding: 0rem 1rem;
  display: flex;
  gap: 10px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 -5px 10px rgba(0, 0, 0, 0.1);
}

.add-to-cart {
  /* Define styles for the "Add to Cart" button */
  background-color: #4caf50;
  color: #ffffff;
  padding: 0.8rem 1.5rem;
  border: none;
  border-radius: 4px;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.add-to-cart:hover {
  /* Define styles for the "Add to Cart" button on hover */
  background-color: #3e8e41;
}

.buy-now {
  /* Define styles for the "Buy Now" button */
  background-color: #ff5722;
  color: #ffffff;
  padding: 0.8rem 1.5rem;
  border: none;
  border-radius: 4px;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.buy-now:hover {
  /* Define styles for the "Buy Now" button on hover */
  background-color: #e64a19;
}
</style>
