<template>
  <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20">
    <path d="m8 14.708-.771-.77L11.167 10 7.229 6.062 8 5.292 12.708 10Z" />
  </svg>
</template>

<script>
export default {
  name: "ChevronRightSvg",
};
</script>
