import { createRouter, createWebHistory } from "vue-router";

import Home from "../views/Home.vue";
// import PLP from "../views/PLP.vue";
import PDP from "../views/PDP.vue";
import Cart from "../views/Cart.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/product-detail/:skuId",
    name: "PDP",
    component: PDP,
  },
  {
    path: "/cart",
    name: "Cart",
    component: Cart,
  },
];
const router = createRouter({
  // mode: "history",
  history: createWebHistory(),
  routes,
  // scrollBehavior(to, from, savedPosition) {
  //     if (savedPosition) {
  //         return savedPosition
  //     } else {
  //         return { x: 0, y: 0, behavior: "smooth" }
  //     }
  // }
});

export default router;
