<template>
  <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20">
    <path
      d="M5.5 15.5h2.292v-4.958h4.416V15.5H14.5V8.25L10 4.854 5.5 8.25Zm-1.083 1.083V7.708L10 3.5l5.583 4.208v8.875h-4.458v-4.958h-2.25v4.958ZM10 10.188Z"
    />
  </svg>
</template>

<script>
export default {
  name: "HomeSvg",
};
</script>
