<template>
  <div class="custom-loader-elm" v-if="isLoading">
    <div class="coin">
      <div class="side heads">
        <img
          class="imageCoin"
          src="../assets/images/rovi-icon.png"
          width="100"
          height="100"
          alt="Rovi Token"
        />
      </div>
      <div class="side tails">
        <img
          class="imageCoin"
          src="../assets/images/rovi-icon.png"
          width="100"
          height="100"
          alt="Rovi Token"
        />
      </div>

      <div class="oval-shadow"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["isLoading"],
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/custom-loader.scss";
</style>
