<template>
  <div class="cart">
    <div class="cart-items">
      <div class="cart-item" v-for="(product, index) in cart" :key="index">
        <div class="cart-item-image">
          <img :src="product.images[0].src" alt="product image" />
        </div>
        <div class="cart-item-details">
          <span class="cart-item-brand">{{ product.brand }}</span>
          <span class="cart-item-name">{{ product.name }}</span>
          <div class="prices">
            <div class="current-price">{{ product.price.current_price }}</div>
            <div class="slashed-price">{{ product.price.slashed_price }}</div>
          </div>
          <div class="quantity">
            <label>Quantity:</label>
            <input type="number" v-model="product.quantity" />
          </div>
        </div>
        <div class="cart-item-actions">
          <RoviButton kind="flat" size="small" @click="removeFromCart(product)"
            >Remove</RoviButton
          >
        </div>
      </div>
      <div v-if="cart.length === 0">Your cart is empty.</div>
    </div>
    <div class="cart-total">
      <h4>Total: {{ getTotal() }}</h4>
      <!-- <button @click="checkout()">Checkout</button> -->
      <RoviButton kind="elevated" showShimmer fulWidth showArrow>
        Checkout</RoviButton
      >
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cart: [
        {
          images: [{ src: require("../assets/images/plp/sunglasses.jpg") }],
          brand: "Nike",
          name: "Air Max 270",
          description:
            "Introducing our Classic Product, the perfect accessory to keep your essentials organized and secure. Made from high-quality, genuine leather, this wallet is both durable and stylish, making it a timeless addition to your wardrobe.",
          price: {
            current_price: "Rs 2100",
            old_price: "Rs 5000",
            discount: "58% off",
          },
          tag: "New",
          quantity: 1,
        },
        {
          images: [{ src: require("../assets/images/plp/sunglasses.jpg") }],
          brand: "Adidas",
          name: "Ultraboost 21",
          description:
            "Introducing our Classic Product, the perfect accessory to keep your essentials organized and secure. Made from high-quality, genuine leather, this wallet is both durable and stylish, making it a timeless addition to your wardrobe.",
          price: {
            current_price: "Rs 3200",
            old_price: "Rs 8000",
            discount: "60% off",
          },
          tag: "New",
          quantity: 1,
        },
      ],
    };
  },
  methods: {
    removeFromCart(product) {
      const index = this.cart.indexOf(product);
      if (index !== -1) {
        this.cart.splice(index, 1);
      }
    },
    getTotal() {
      return this.cart
        .reduce((total, product) => {
          return (
            total +
            parseFloat(product.price.current_price.slice(3)) * product.quantity
          );
        }, 0)
        .toFixed(2);
    },
    checkout() {
      // Redirect to checkout page or display a message
    },
  },
};
</script>

<style scoped>
.cart {
  /* Add your styles for the cart page here */
}

.cart-items {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
}

.cart-item {
  margin: 1rem;
  text-align: center;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  align-items: center;
}

.cart-item-image {
  width: 50%;
  height: 200px;
  overflow: hidden;
}

.cart-item-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.cart-item-details {
  width: 50%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: baseline;
}

.cart-item-brand {
  font-size: 0.7rem;
  font-weight: 500;
  margin: 0;
}

.cart-item-name {
  font-size: 0.8rem;
  font-weight: 800;
  margin: 0.5rem 0;
}

.prices {
  display: flex;
  gap: 10px;
  justify-content: left;
  align-items: center;
}

.current-price {
  font-size: 1rem;
  font-weight: bold;
}

.slashed-price {
  font-size: 0.8rem;
  color: #888;
  text-decoration: line-through;
}

.quantity {
  margin-top: 1rem;
  display: flex;
  gap: 10px;
  flex-direction: row;
  align-items: baseline;
}

.quantity label {
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
}

.quantity input {
  width: 12%;
  border: none;
  border-bottom: 1px solid #ccc;
  text-align: left;
  font-size: 1rem;
}

.cart-item-actions {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cart-total {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
