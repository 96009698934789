import { createApp } from "vue";
import VueCookies from "vue3-cookies";
import Neopoprovi_v3 from "neopoprovi_vue3";

import router from "./router";
import store from "./store";

import App from "./App.vue";

const app = createApp(App);

app.use(router).use(store).use(VueCookies).use(Neopoprovi_v3).mount("#app");
